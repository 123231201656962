import axios from 'axios';
import Parse from 'parse';
import { getUrl } from '../utils';
import { OverlayGroup } from './overlayGroups';
import { actionWithLoader, push } from './utils';

const Overlay = Parse.Object.extend('Overlay');

export const getOverlaysPerPage = (limit = 10, skip = 0, filter = '', subgroupId) => {
  return async (dispatch) => {
    const query = new Parse.Query('Overlay')
      .include('group')
      .notEqualTo('deleted', true)
      .limit(limit)
      .skip(skip)
      .descending('updatedAt')
      .withCount(true);

    if (filter !== '') {
      query.contains('name', filter);
    }

    if (subgroupId) {
      const group = new OverlayGroup();
      group.id = subgroupId;
      query.equalTo('group', group);
    }

    const overlays = await query.find();

    dispatch({
      type: 'GET_OVERLAYS',
      overlays: overlays.results.map((overlay) => overlay.toJSON()),
      count: overlays.count,
    });
  };
};

export const createOverlay = (values) => {
  return actionWithLoader(async (dispatch) => {
    const newValues = { ...values };

    // #1 - Create overlay
    const overlay = new Overlay();
    overlay.set('name', newValues.name);
    overlay.set('description', newValues.description);

    if (newValues.subgroup) {
      const overlaySubGroup = new OverlayGroup();
      overlaySubGroup.id = newValues.subgroup.value;
      overlay.set('group', overlaySubGroup);
    }

    // #2 - Upload image
    if (newValues.image instanceof File) {
      const formData = new FormData();
      formData.append('file', newValues.image);
      const API_SERVER_URL = getUrl() + '/api';
      const response = await axios.post(API_SERVER_URL + '/uploadOverlay', formData);
      if (response.data) {
        overlay.set('image', response.data.fileName);
      }
    }

    await overlay.save();

    // #3 - Update store
    dispatch({
      type: 'ADD_OVERLAY',
      payload: overlay.toJSON(),
    });
  });
};

export const deleteOverlay = (id) => {
  return actionWithLoader(async (dispatch) => {
    // #1 - Remove overlay in database
    const overlay = await new Parse.Query(Overlay).get(id);
    if (!overlay) {
      throw new Error('Overlay not found');
    }
    overlay.set('deleted', true);
    await overlay.save();

    // #2 - Update store
    dispatch({
      type: 'DELETE_OVERLAY',
      id,
    });
  });
};

export const editOverlay = (id) => {
  return async (dispatch) => {
    const overlay = await new Parse.Query(Overlay).include('group').get(id);
    if (!overlay) {
      throw new Error('Overlay not found');
    }

    dispatch({
      type: 'EDIT_OVERLAY',
      overlay: overlay.toJSON(),
    });
  };
};

export const updateOverlay = (overlay, values) => {
  return actionWithLoader(async (dispatch) => {
    const newValues = { ...values };

    const updatedOverlay = new Overlay();
    updatedOverlay.id = overlay.objectId;

    updatedOverlay.set('name', newValues.name);
    updatedOverlay.set('description', newValues.description);

    if (newValues.image instanceof File) {
      const formData = new FormData();
      formData.append('file', newValues.image);

      const API_SERVER_URL = getUrl() + '/api';
      const response = await axios.post(API_SERVER_URL + '/uploadOverlay', formData);
      if (response.data) {
        updatedOverlay.set('image', response.data.fileName);
      }
    }

    if (newValues.subgroup) {
      const overlaySubGroup = new OverlayGroup();
      overlaySubGroup.id = newValues.subgroup.value;
      updatedOverlay.set('group', overlaySubGroup);
    }

    await updatedOverlay.save();

    dispatch({
      type: 'UPDATE_OVERLAY',
      overlay: updatedOverlay.toJSON(),
    });
  });
};

export const reorderOverlays = (values) => {
  return async (dispatch) => {
    const promises = values.map((o, index) => {
      const overlay = new Overlay();
      overlay.id = o.objectId;
      overlay.set('order', index);
      return overlay.save();
    });
    const overlays = await Promise.all(promises);

    dispatch({
      type: 'REORDER_OVERLAYS',
      overlays: overlays.map((o) => o.toJSON()),
    });
  };
};

//--------------------------------------------------------//
//-------------------- Redirection -----------------------//
//--------------------------------------------------------//
export function goToOverlays() {
  return push('/overlays');
}
