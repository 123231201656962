import Sortable from 'devextreme-react/sortable';
import TabPanel from 'devextreme-react/tab-panel';

const CustomTabPanel = ({
  tabs,
  selectedItem,
  onSelectionChanged,
  onTabDragStart,
  onTabDrop,
  children,
  renderTitle,
  style = {}
}) => {
  return (
    <Sortable filter=".dx-tab" data={tabs} itemOrientation="horizontal" dragDirection="horizontal" onDragStart={onTabDragStart} onReorder={onTabDrop}>
      <TabPanel
        style={style}
        dataSource={tabs}
        itemTitleRender={renderTitle}
        deferRendering={true}
        showNavButtons={true}
        selectedItem={selectedItem}
        repaintChangesOnly={true}
        onSelectionChanged={onSelectionChanged}
        itemComponent={(props) => (
          <div key={props.data.id} >
            {children}
          </div>
        )}
      />
    </Sortable>
  );
};

export default CustomTabPanel;
