const initialState = {
  overlays: [],
  overlay: {},
  count: 0,
};

const overlays = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OVERLAYS':
      return {
        ...state,
        overlays: action.overlays,
        count: action.count,
      };
    case 'ADD_OVERLAY':
      return {
        ...state,
        overlays: [action.payload, ...state.overlays],
      };
    case 'DELETE_OVERLAY':
      return {
        ...state,
        overlays: state.overlays.filter((overlay) => overlay.objectId !== action.id),
      };
    case 'EDIT_OVERLAY':
      return {
        ...state,
        overlay: action.overlay,
      };
    case 'UPDATE_OVERLAY':
      return {
        ...state,
        overlays: state.overlays.map((overlay) => {
          if (overlay.objectId === action.overlay.objectId) {
            return action.overlay;
          }
          return overlay;
        }),
        overlay: action.overlay,
      };
    case 'REORDER_OVERLAYS':
      return {
        ...state,
        overlays: action.overlays,
      };
    default:
      return state;
  }
};

export default overlays;
