import Parse from 'parse';

export const OverlayGroup = Parse.Object.extend('OverlayGroup');

// not use function actionWithLoader because loading is not necessary

export const createOverlayGroup = (values, hasParent = false) => {
  return async (dispatch, getState) => {
    const overlayGroup = new OverlayGroup();
    overlayGroup.set('name', values.name);
    if (hasParent) {
      const parent = new OverlayGroup();
      parent.id = values.parent;
      overlayGroup.set('parent', parent);
    }
    await overlayGroup.save();

    dispatch({
      type: hasParent ? 'ADD_OVERLAY_SUBGROUP' : 'ADD_OVERLAY_GROUP',
      payload: overlayGroup.toJSON(),
    });

    return overlayGroup.toJSON();
  };
};

export const getOverlayGroups = (hasParent = false) => {
  return async (dispatch, getState) => {
    const query = new Parse.Query(OverlayGroup);
    if (hasParent) {
      query.include('parent').exists('parent');
    } else {
      query.doesNotExist('parent');
    }
    const overlayGroups = await query.find();

    dispatch({
      type: hasParent ? 'GET_OVERLAY_SUBGROUPS' : 'GET_OVERLAY_GROUPS',
      [hasParent ? 'overlaySubgroups' : 'overlayGroups']: overlayGroups.map((group) => group.toJSON()),
    });
  };
};

export const getOverlayGroupsByParent = (parentId) => {
  return async (dispatch, getState) => {
    if (!parentId) return;
    const parent = new OverlayGroup();
    parent.id = parentId;

    const overlayGroups = await new Parse.Query(OverlayGroup).equalTo('parent', parent).ascending('order').find();

    dispatch({
      type: 'GET_OVERLAY_SUBGROUPS',
      overlaySubgroups: overlayGroups.map((group) => group.toJSON()),
    });
  };
};

export const reorderOverlayGroups = (values, hasParent) => {
  return async (dispatch, getState) => {
    const promises = values.map((group, index) => {
      const overlayGroup = new OverlayGroup();
      overlayGroup.id = group.id;
      overlayGroup.set('order', index);
      return overlayGroup.save();
    });
    const overlayGroups = await Promise.all(promises);

    dispatch({
      type: hasParent ? 'REORDER_OVERLAY_SUBGROUPS' : 'REORDER_OVERLAY_GROUPS',
      [hasParent ? 'overlaySubgroups' : 'overlayGroups']: overlayGroups.map((group) => group.toJSON()),
    });
  };
};