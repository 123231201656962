import React from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import FormField from '../../../components/form/FormField';
import NumberField from '../../../components/form/NumberField';
import DropFileUploader from '../../../components/form/DropFileUploader';
import ReduxFormSelect from '../../../components/reduxFormMUIWrapper/ReduxFormSelect';
import ReduxFormInputColor from '../../../components/reduxFormMUIWrapper/ReduxFormInputColor';
import { fonts, textAlignments } from '../../../constant';

import { updateLayer } from '../../../actions/templates';
import ReduxFormSwitch from '../../../components/reduxFormMUIWrapper/ReduxFormSwitch';

const useStyles = makeStyles({
  root: {
    composes: 'flexColumn stretch',
  },
  section: {
    width: '100%',
  },
  row: {
    composes: 'flexRow spaceAround',
    width: '100%',
  },
  selectField: {
    minWidth: 160,
  },
  containerColor: {
    position: 'absolute',
    zIndex: 100,
    bottom: -10,
  },
  title: {
    fontWeight: 400,
    fontSize: 16,
    padding: 0
  },
  formContainer: {
    composes: 'flexRow spaceBetween form-container',
    width: '50%'
  },
  rowContainer: {
    padding: '0 10px'
  },
  dimensions: {
    width: 100,
  },
  switchField: {
    width: 'auto',
  },
});

const LayerForm = (props) => {
  // props
  const { handleSubmit } = props;

  // styles
  const classes = useStyles(props);

  const selector = formValueSelector('propertiesForm');
  const layerType = useSelector(state => selector(state, 'type'));

  //---- render ----//
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <div className={classes.rowContainer}>
        <FormField label='Nom' name='name' fullWidth />
      </div>
      <div className={classes.rowContainer}>
        <div>Dimensions</div>
        <div className={classes.formContainer}>
          <NumberField label='largeur' name='width' />
          <NumberField label='hauteur' name='height' />
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div>Position</div>
        <div className={classes.formContainer}>
          <NumberField label='haut' name='top' authorizeNegatif />
          <NumberField label='gauche' name='left' authorizeNegatif />
        </div>
      </div>

      {layerType === 'userImage' && (
        <div className={classes.rowContainer}>
          <div className={classes.formContainer}>
            <NumberField label='Rotation en °' name='rotation' authorizeNegatif />
          </div>
        </div>
      )}

      {layerType === 'background' && (
        <>
          <div className={classes.rowContainer}>
            <div className={classes.formContainer}>
              <NumberField label='Rotation en °' name='rotation' authorizeNegatif />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.formContainer}>
              <FormField
                fullWidth
                name="backgroundColor"
                label="Couleur de fond"
                component={ReduxFormInputColor}
                colorClassName={classes.containerColor}
                rootClassName={classes.inputColor}
              />
            </div>
          </div>
        </>
      )}

      {layerType === 'userText' && (
        <>
          <div className={classes.rowContainer}>
            <FormField
              label='Texte'
              name='text'
              fullWidth
            />
            <div className={classes.formContainer}>
              <FormField
                label='Police'
                name='font'
                component={ReduxFormSelect}
                valueClassName={classes.selectField}
                displayEmpty
              >
                {fonts.map((type, index) => (
                  <MenuItem value={type} key={index}>
                    {type}
                  </MenuItem>
                ))}
              </FormField>
              <NumberField label='Taille' name='size' />
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.formContainer}>
              <FormField
                label='Alignement'
                name='alignment'
                component={ReduxFormSelect}
                valueClassName={classes.selectField}
                displayEmpty
              >
                {textAlignments.map((alignment, index) => (
                  <MenuItem value={alignment.value} key={index}>
                    {alignment.label}
                  </MenuItem>
                ))}
              </FormField>
              <FormField
                name='color'
                label='Couleur'
                component={ReduxFormInputColor}
                colorClassName={classes.containerColor}
              />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.formContainer}>
              <NumberField label='Rotation en °' name='rotation' authorizeNegatif />
            </div>
          </div>
        </>
      )}

      {(layerType === 'image' || layerType === 'mask') && (
        <>
          <div className={classes.rowContainer}>
            <div className={classes.formContainer}>
              <NumberField label='Rotation en °' name='rotation' authorizeNegatif />
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.formContainer}>
              <FormField
                label='Image'
                name='imageFile'
                component={DropFileUploader}
                fullWidth
              />
            </div>
            {layerType === 'mask' && (
              <div className={classes.formContainer}>
                <FormField
                  label="Utilisé pour l'impression"
                  name='usedForPrint'
                  component={ReduxFormSwitch}
                  inputClassName={classes.switchField}
                  fullWidth
                  horizontal
                  labelPosition="after"
                // rootClassName={classes.field}
                />
              </div>
            )}
          </div>
        </>
      )}


      <input type='submit' />
    </form>
  );
};

LayerForm.propTypes = {
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
export default reduxForm({
  form: 'propertiesForm',
  enableReinitialize: true,
  onChange: (values, dispatch) => {

    // TODO change the layer and dispatch TEMPLATE_UPDATED ou LAYER_UPDATED
    //dispatch(selectLayer(values));
    dispatch(updateLayer(values));
  },
})(LayerForm);
