import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp'
import { Column } from 'devextreme-react/data-grid';
import { formatPrice } from '../../utils';

import Table from '../../components/devExpressTable/Table';
import CustomCell from '../../components/devExpressTable/CustomCell';

const useStyles = makeStyles({
	root: {},
	cell: {
		composes: 'flexRow stretch center',
		height: 50,
	},
	right: {
		composes: 'justifyEnd'
	},
	imageContainer: {
		width: 60,
		height: 60,
	},
	image: {
		width: '100%',
	}
});

const columns = [
	{
		title: 'image',
		dataIndex: 'image_url',
		key: 'image_url',
		render: (value) => {
			return <img src={value} alt="product" style={{ width: 50, height: 50, objectFit: 'contain' }} />;
		},
	},
	{
		title: 'Items',
		dataIndex: 'items',
		key: 'items',
		render: (value) => {
			return (
				<div>
					<div style={{ fontWeight: 'bold' }}>{value?.product?.name}</div>
					<span>{value?.variant?.name}</span>
				</div>
			);
		},
	},
	{
		title: 'Quantité',
		dataIndex: 'quantity',
		key: 'quantity',
	},
	{
		title: 'Prix',
		dataIndex: 'price',
		key: 'price',
		render: (value) => formatPrice(value),
	},
	{
		title: 'Total',
		dataIndex: 'total',
		key: 'total',
		render: (value) => formatPrice(value),
	},
	{
		title: '',
		dataIndex: 'image_url',
		key: 'image_url',
		render: (value) =>
			<a
				href={value}
				target="_blank"
				rel="noreferrer"
			>
				<GetAppIcon />
			</a>
	},
];

const ModalProductTable = ({ data }) => {

	// styles
	const classes = useStyles();

	const formatDataSource = (order) => {
		if (!order) return [];
		const items = order.items || [];
		const montages = order?.metadata?.montages;
		if (montages) {
			return montages.map((montage, i) => ({
				key: i,
				image_url: montage.image,
				items: items[Math.min(i, items.length - 1)],
				quantity: montage.product?.quantity ?? 0,
				price: montage.product?.variant?.price ?? 0,
				total: montage.product?.quantity * (montage.product?.variant?.price ?? 0),
			}));
		}
		return items.map((item, i) => ({
			key: i,
			items: item,
			quantity: item?.quantity,
			price: item?.price,
			total: item?.price_total,
			image_url: ""
		}));
	};

	// table data sources
	const getDataSource = useCallback(() => formatDataSource(data)
		, [data]);

	return (
		<div className={classes.root}>
			<Table
				dataSource={getDataSource()}
				showColumnLines={false}
				invisibleInputSearch={true}
			>
				{
					columns.map((column, i) => (
						<Column
							key={i}
							dataField={column.dataIndex}
							caption={column.title}
							cellRender={({ data }) => <>
								<CustomCell
									value={column.render ? column.render(data[column.dataIndex]) : data[column.dataIndex]}
								/>
							</>}
						/>
					))
				}
			</Table>
		</div>
	)
}

ModalProductTable.propTypes = {
	data: PropTypes.any,
};
export default ModalProductTable;