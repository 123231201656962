import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'devextreme-react/button';
import RefreshButton from '../buttons/RefreshButton';

import ModalDialog from '../ModalDialog';

const useStyles = makeStyles({
	root: {
		composes: 'flexRow stretch center justifyCenter',
	},
	button: {
		border: 'none',
		backgroundColor: 'transparent',
	},
	fullHeight: {
		height: 50,
	},
});

const TableButtonsAction = (props) => {
	// props
	const { onPreview, onEdit, onDelete, onRefresh, item, openDialog, label, onMove, fullHeight = true } = props;

	// state
	const [open, setOpen] = useState(false);

	// open local dialog
	const handleOpenDialog = useCallback(() => {
		setOpen(openDialog);
	}, [openDialog]);

	// styles
	const classes = useStyles();

	// preview action
	const handlePreview = ({ event }) => {
		event.stopPropagation();
		onPreview(item);
	}

	// preview action
	const handleMove = ({ event }, up) => {
		event.stopPropagation();
		onMove(up);
	}
	// close dialog
	const _closeDialog = () => setOpen(false);

	// delete action
	const handleDelete = () => {
		onDelete(item);
		_closeDialog();
	}

	// edit action
	const handleEdit = ({ event }) => {
		event.stopPropagation();
		onEdit(item);
	}

	// open dialog
	const onDialogOpen = ({ event }) => {
		event.stopPropagation();
		handleOpenDialog();
		setOpen(true);
	}


	return (
		<div className={classNames(classes.root, { [classes.fullHeight]: fullHeight })}>
			{onPreview && (
				<Button
					icon="movetofolder"
					onClick={handlePreview}
					className={classes.button}
				/>
			)}
			{onMove && (
				<>
					<Button
						icon="chevronup"
						onClick={(evt) => handleMove(evt, true)}
						className={classes.button}
					/>
					<Button
						icon="chevrondown"
						onClick={(evt) => handleMove(evt)}
						className={classes.button}
					/>
				</>
			)}
			{onEdit && (
				<Button
					icon="edit"
					onClick={handleEdit}
					className={classes.button}
				/>
			)}
			{onDelete && <Button
				icon="trash"
				onClick={onDialogOpen}
				className={classes.button}
			/>}

			{onRefresh && <RefreshButton onRefresh={onRefresh} color="default" />}

			{/* ----------------- Template Deletion Dialog ----------------- */}
			<ModalDialog
				title="Suppression"
				content={<span>Voulez-vous supprimer <b>{label}</b>?</span>}
				isVisible={open}
				onConfirm={handleDelete}
				onClose={_closeDialog}
			/>
		</div>
	)
}

TableButtonsAction.propTypes = {
	item: PropTypes.any,
	onPreview: PropTypes.func,
	onEdit: PropTypes.func,
	onRefresh: PropTypes.func,
	onDelete: PropTypes.func,
	openDialog: PropTypes.any,
	label: PropTypes.string,
	fullHeight: PropTypes.bool,
};

export default TableButtonsAction;