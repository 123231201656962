import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Layer, Rect, Stage } from 'react-konva';
import { addMarginToCanvas, containCenter, getPathImageGCP, layerToCanvasProps, mapLayersRecursively } from '../../../utils';
import useImage from 'use-image';
import { useEffect, useMemo, useRef, useState } from 'react';
import LayerPreviewer from './LayerPreviewer';
import LayerEditor from './LayerEditor';

export const flattenLayers = (layers, templateId) =>
  layers.reduce((acc, layer) => {
    if (layer.type === 'mask' && layer.layers && layer.layers.length > 0) {
      return [
        ...acc,
        ...flattenLayers(
          layer.layers.map((f) => ({
            ...f,
            parentLeft: layer.left + (layer.parentLeft ?? 0),
            parentTop: layer.top + (layer.parentTop ?? 0),
            masks: [
              {
                url: layer.imageId ? getPathImageGCP(templateId, layer.imageId) : `${process.env.PUBLIC_URL}/${layer.type}.jpg`,
                ...layer,
              },
              ...(f.masks ?? []),
              ...(layer.masks ?? []),
            ],
          })),
          templateId
        ),
      ];
    }
    return [...acc, layer];
  }, []);

export const flattenLayersWithMask = (layers) => {
  return layers.reduce((acc, layer) => {
    if (layer.type === 'mask' && layer.layers && layer.layers.length > 0) {
      return [
        ...acc,
        {
          ...layer,
          parentLeft: layer.parentLeft ?? 0,
          parentTop: layer.parentTop ?? 0,
        },
        ...flattenLayersWithMask(
          layer.layers.map((f) => ({
            ...f,
            parentLeft: layer.left + (layer.parentLeft ?? 0),
            parentTop: layer.top + (layer.parentTop ?? 0),
          })))
      ];
    }
    return [...acc, {
      ...layer,
      parentLeft: layer.parentLeft ?? 0,
      parentTop: layer.parentTop ?? 0,
    }];
  }, []);
}

export const resizeLayers = (layers, sizeRatio) => {
  const result = layers.map((layer) => {
    const { top, left, parentLeft, parentTop, width, height } = layer;
    return {
      ...layer,
      left: left * sizeRatio,
      top: top * sizeRatio,
      parentLeft: (parentLeft ?? 0) * sizeRatio,
      parentTop: (parentTop ?? 0) * sizeRatio,
      width: width * sizeRatio,
      height: height * sizeRatio,
      masks: (layer.masks || []).map((mask) => ({
        ...mask,
        left: mask.left * sizeRatio,
        top: mask.top * sizeRatio,
        parentLeft: (mask.parentLeft ?? 0) * sizeRatio,
        parentTop: (mask.parentTop ?? 0) * sizeRatio,
        width: mask.width * sizeRatio,
        height: mask.height * sizeRatio,
      })),
    };
  });
  return result;
};

const reccursiveHaveBackgroundTypeLayer = (layers) => {
  for (let i = 0; i < layers.length; i++) {
    if (layers[i].type === 'background') {
      return true;
    }
    if (layers[i].type === 'mask') {
      if (reccursiveHaveBackgroundTypeLayer(layers[i].layers)) {
        return true;
      }
    }
  }
  return false;
}

const useStyles = makeStyles(() => ({
  editor: {
    composes: 'flex1 flexRow center justifyCenter nowrap',
    width: '100%',
  },
  stageContainer: {
    position: 'absolute',
    height: '90%',
    '&': {
      height: 'calc(100% - 45px)',
    },
    width: '100%',
    overflow: 'auto',
  },
}));
const Editor = ({
  layers,
  template,
  selectedLayer,
  onChange,
  onClickOutside,
  onClickLayer,
  zoom = 1,
}) => {
  const classes = useStyles();
  const resizingDelayTimer = useRef(null);
  const withBackground = reccursiveHaveBackgroundTypeLayer(layers);

  const widthInPx = template.getWidthInPx();
  const heightInPx = template.getHeightInPx();

  const [sizeRatio, setSizeRatio] = useState({
    ratio: 1,
    width: widthInPx,
    height: heightInPx,
  });
  const elementRef = useRef(null);

  const [transparent] = useImage(`${process.env.PUBLIC_URL}/transparent.png`, 'anonymous');

  useEffect(() => {
    if (!elementRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      clearTimeout(resizingDelayTimer.current);
      resizingDelayTimer.current = setTimeout(() => {
        const boundingRect = elementRef.current.getBoundingClientRect();
        const sizeWidth = boundingRect.width ?? 1;
        const sizeHeight = boundingRect.height ?? 1;
        const ratio = sizeWidth / widthInPx;
        setSizeRatio({
          ratio,
          width: sizeWidth,
          height: sizeHeight,
        })
      }, 100)
    });
    resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const size = useMemo(
    () => {
      const zoomType = zoom < 10 ? 'fit' : 'full';
      const zoomValue = zoom % 10;
      if (zoomType === 'full') {
        return {
          width: widthInPx * zoomValue,
          height: heightInPx * zoomValue,
          ratio: zoomValue
        }
      }
      const { width, height } = containCenter(sizeRatio.width, sizeRatio.height, widthInPx, heightInPx)
      return {
        width: width * zoomValue,
        height: height * zoomValue,
        ratio: width / widthInPx * zoomValue
      }
    },
    [sizeRatio, heightInPx, widthInPx, zoom]
  );

  const handleClickOutside = (e) => {
    if (e.currentTarget === e.target)
      onClickOutside && onClickOutside();
  }

  const onHoverLayer = (e) => {
    e.target.stroke('rgb(153, 153, 153)');
  }

  const onLeaveLayer = (e) => {
    e.target.stroke('transparent');
  }

  const handleClickLayer = (layer) => {
    onClickLayer && mapLayersRecursively(layers, layer.id, onClickLayer);
  }

  return <div className={classes.editor} ref={elementRef} >
    <div
      className={classes.stageContainer}
      onClick={handleClickOutside}
    >
      <Stage
        width={size.width}
        height={size.height}
      >
        <Layer
          {...addMarginToCanvas(size)}
        >
          <Rect
            width={size.width}
            height={size.height}
            fillPatternImage={transparent}
          />
        </Layer>
        {
          resizeLayers(flattenLayers(layers, template.id).filter(({ hide }) => !hide), size.ratio).map((layer) => {
            return <LayerPreviewer
              template={template}
              key={layer.id}
              layer={{
                ...layer,
                ...layer.type === 'userImage' ? { withBackground } : {}
              }}
            />
          })
        }
        {
          resizeLayers(flattenLayersWithMask(layers).filter(({ hide }) => !hide), size.ratio).map((layer) => {
            return <Layer {...addMarginToCanvas(layer)} key={layer.id}>
              <Rect
                {...layerToCanvasProps(layer)}
                onMouseOver={onHoverLayer}
                onMouseLeave={onLeaveLayer}
                onClick={() => handleClickLayer(layer)}
                stroke="transparent"
                strokeWidth={1}
                dash={[5, 2]}
              />
            </Layer>
          })
        }
        {selectedLayer && <LayerEditor
          layer={{
            ...flattenLayersWithMask(layers).find(({ id }) => id === selectedLayer.id),
            ...selectedLayer.type === 'userImage' ? { withBackground } : {},
            imageTempUrl: selectedLayer?.imageTemp ? URL.createObjectURL(selectedLayer.imageTemp) : null
          }}
          template={template}
          sizeRatio={size.ratio}
          onChange={onChange}
        />
        }
      </Stage>
    </div>
  </div >
}

Editor.propTypes = {
  layers: PropTypes.array,
  template: PropTypes.object,
  selectedLayer: PropTypes.object,
};

export default Editor;